/*global _, $, jQuery, MatchHeightHelper*/
import Helper from "./services/Helper/Helper.js";
import Select from "./widgets/Select/Select.js";

(function ($) {
  $(function () {
    var $body = $("body"),
      nativeCookiesActivated = $body.data("cookies-activated"),
      cookieAcceptLinkText = $body.data("cookiesLinkAcceptText"),
      cookieLinkText = $body.data("cookiesLinkText"),
      cookieMessage = $body.data("cookiesMessage"),
      cookiePolicyUrl = $body.data("cookiesPolicyUrl"),
      cookiePosition = "bottom" === $body.data("cookiesPosition"),
      country = $body.data("country") ? $body.data("country") : "fr";
    if (nativeCookiesActivated) {
      $.cookieCuttr({
        cookieAnalytics: false,
        cookieMessage:
          cookieMessage +
          ("fr" === country
            ? '&nbsp;<a class="cc-cookie-more" target="_blank" href="{{cookiePolicyLink}}">' +
              cookieLinkText +
              "</a>"
            : ""),
        cookieAcceptButtonText: cookieAcceptLinkText,
        cookiePolicyLink: cookiePolicyUrl,
        cookieNotificationLocationBottom: cookiePosition,
      });
    }

    // Init linkify
    require("linkifyjs/jquery")($, document);
    $(".linkify").linkify();

    // Init Fancybox
    require("fancybox/dist/js/jquery.fancybox.cjs.js")($);
    $(".fancybox").fancybox();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ("undefined" !== typeof $.fn.validate && $.isFunction($.fn.validate)) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        highlight: function (element) {
          // Add the .has-error class to the form-group.
          $(element).closest(".form-group").addClass("has-error");
        },
        unhighlight: function (element) {
          // Remove the .has-error class from the form-group.
          $(element).closest(".form-group").removeClass("has-error");
        },
        errorElement: "span",
        errorClass: "help-block",
        errorPlacement: function (error, element) {
          // Handle the error placement for checkbox & radio.
          if (
            element.parent(".input-group").length ||
            "checkbox" === element.prop("type") ||
            "radio" === element.prop("type")
          ) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        },
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod(
        "cemail",
        function (value, element, regexp) {
          regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

          return this.optional(element) || regexp.test(value);
        },
        "Please enter a valid email address"
      );

      $.validator.addMethod("pattern", function (value, element, regexp) {
        var pattern = new RegExp(regexp);

        return this.optional(element) || pattern.test(value);
      });

      // $.validator.addMethod('alphanumeric', function(value, element) {
      //   var regexp = new RegExp(/^[\u4e00-\u9fff_A-Za-zа-яёЁÀ-Ÿ][\u4e00-\u9fff_a-zа-яёЁÀ-Ÿ0-9\-\s]+$/i);

      //   return regexp.test(value);
      // }, 'Letters, numbers, spaces or dashes only');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $(".js-contact-form, .js-newsletter-form").each(function (i, e) {
        $(e).validate({
          ignore: [],
          rules: {
            "front_message_type[email]": {
              email: false,
              cemail: true,
            },
            "front_newsletter_type[email]": {
              email: false,
              cemail: true,
            },
            hiddenRecaptcha: {
              required: true,
            }
          }
        });
      });

      $(".js-press-review-form").validate({
        rules: {
          "front_press_review_type[pressReviewFields][costCenter]": {
            pattern: "^[0-9]{4}$",
          },
          "front_press_review_type[pressReviewFields][entityCode]": {
            pattern: "^[a-zA-Z]{3}[0-9]{3}$",
          },
        },
        messages: {
          "front_press_review_type[pressReviewFields][costCenter]": {
            pattern: $(
              '[name="front_press_review_type[pressReviewFields][costCenter]"]'
            ).data("msg-error"),
          },
          "front_press_review_type[pressReviewFields][entityCode]": {
            pattern: $(
              '[name="front_press_review_type[pressReviewFields][entityCode]"]'
            ).data("msg-error"),
          },
        },
      });

      $(".max_length_rule").each(function () {
        $(this).rules("add", {
          maxlength: 100,
          alphanumeric: true,
          messages: {
            maxlength: $(this).data("msg-error"),
          },
        });
      });

      $(".email_rule").each(function () {
        $(this).rules("add", {
          email: false,
          cemail: true,
          messages: {
            maxlength: $(this).data("msg-error"),
          },
        });
      });

      $(".js-press-review-form input").blur(function () {
        let isValid = $(this).attr("aria-invalid");
        isValid = isValid === "true";

        $("#front_press_review_type_submit").attr("disabled", isValid);
      });
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $(".match-height .thumbnail.grid").not(".file-icon").matchHeight({
      byRow: false,
    });
    $(".js-match-height .card").matchHeight();

    $(".js-match-height .js-match-height-item").matchHeight();

    $(".js-buckle--spokesperson").on(
      "ajaxComplete.jq.buckle",
      function (event) {
        let $container = event.buckle.getContainer();
        MatchHeightHelper.update($container[0], true, ".js-match-height .card");
      }
    );

    $(".js-buckle--assets").on(
      "showing.jq.offie-dropdown",
      '[data-toggle="offie-dropdown"]',
      function (event) {
        let buckle = $(event.target)
          .closest(".js-buckle--folder")
          .data("buckle");

        // Fetch content of the folder.
        buckle.fetch({});
      }
    );

    $(".js-buckle--assets").on("ajaxComplete.jq.buckle", function (event) {
      let $element = event.buckle.getElement(),
        $container = event.buckle.getContainer();
      // Update the grid.
      MatchHeightHelper.update($container[0], true, ".js-match-height .card");

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      format: "yyyy-mm-dd",
      startView: "year",
      minView: "month",
      fontAwesome: true,
      autoclose: true,
    };

    $("#front_search_type_start").datetimepicker(dateTimePickerOptions);
    $("#front_search_type_end").datetimepicker(dateTimePickerOptions);

    require("./modules/share.js");

    Helper.convertToArray(document.querySelectorAll("select")).map(
      (select) => new Select(select)
    );

    // Press Review
    let pressReviewLink = $(
      ".section--press_review-jumbotron .section-btn"
    ).attr("href");

    $(".section--press-review-index .section-btn").attr(
      "href",
      pressReviewLink
    );

    $(".btn-close-searchform").on("click", function (e) {
      e.preventDefault();

      $(this).removeClass("active");
      $(".navbar-nav .nav-item--search .nav-link").show();
      $("#offie--search .offie-collapse").removeClass("offie--active");
      $("#offie--search .offie-collapse").css("height", "0");
    });

    $(".right-block .navbar-knob--menu").on("click", function () {
      if ($(window).width() > 992) {
        let getHeight = $(
          ".layout-hook--header .section--header .section-block"
        ).outerHeight();

        $(this).toggleClass("active");
        $(
          ".layout-hook--header .section--header .section-block, .layout-hook--header .section--header .header-nav"
        ).toggleClass("active-show");
        $(".header-nav.active-show").css("top", getHeight);

        if ($("#offie--search .offie-collapse").hasClass("offie--active")) {
          $(".btn-close-searchform").trigger("click");
        }
      } else {
        $(".header-mobile-opened").addClass("active");
        $("body").css("overflow", "hidden");
      }
    });

    $(".layout-hook--header .header-mobile-opened .navbar-knob--menu").on(
      "click",
      function () {
        $(".header-mobile-opened").removeClass("active");
        $("body").css("overflow", "inherit");
      }
    );

    $(".navbar-nav .nav-item--search .nav-link").on("click", function (e) {
      e.preventDefault();
      $(this).hide();

      let getHeight = $(
        ".layout-hook--header .section--header .section-block"
      ).outerHeight();

      $("#offie--search .offie-collapse").css("top", getHeight);
      $(".btn-close-searchform").addClass("active");

      if (
        $(".layout-hook--header .section--header .section-block").hasClass(
          "active-show"
        )
      ) {
        $(".right-block .navbar-knob--menu").trigger("click");
      }
    });

    $(".header-mobile-opened .header-group .header-group-title").on(
      "click",
      function () {
        $(this).parent().toggleClass("dropdown-active");
      }
    );

    let btnGoToTop = $("#btn_goto_top");

    function scrollToTop(ele, transition) {
      $("body, html").animate(
        {
          scrollTop: $(ele).offset().top,
        },
        transition
      );
    }

    if (btnGoToTop) {
      btnGoToTop.on("click", function () {
        scrollToTop("#content", 500);
      });
    }
  });
}
)(jQuery);
